import {BasicIntroduction} from "./BasicIntroduction";
import {OpensourceContributions} from "./OpensourceContributions";
import {Footer} from "./Footer";
import {GetInTouch} from "./GetInTouch";

/**
 * Home Page Functional Component
 * @constructor
 */
export const HomePage = () => {
    return (
        <div className={'justify-between'}>
            <BasicIntroduction/>
            <OpensourceContributions/>
            <GetInTouch/>
            <Footer/>
        </div>
    )
}
