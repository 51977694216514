export const Footer = () => {
    return <div className={`
        flex 
        flex-col
        items-center 
        justify-center
        text-gray-400
        text-xs
        pb-5
        bg-gray-950 
    `}>
        <p>Made with React and deployed on Self Hosted Kubernetes with ❤️</p>
        <br/>
        <span>&copy; 2024 aseemsavio.com. All Rights Reserved.</span>
    </div>
}
