import {ProjectTileProps} from "../components/ProjectTile";
import {Carousel} from "../components/Carousel";
import {GradientHeading} from "../components/GradientHeading";

export const OpensourceContributions = () => {

    const projects: ProjectTileProps[] = [

        {
            projectName: "dynamo-kt",
            badges: ["kotlin", "java", "coroutines", "author", "maintainer", "code-generation", "aws", "dynamo-db", "sdk", "MIT-license"],
            spanText: (
                <>
                    <span>I created this</span>
                    <span className={"text-white"}>Kotlin</span>
                    <span>library to add support for</span>
                    <span className={"text-white"}>immutable data classes</span>
                    <span>in the</span>
                    <span className={"text-white"}>AWS Dynamo DB SDKs.</span>
                    <span>This is achieved through</span>
                    <span className={"text-white"}>code generation</span>
                    <span>at build time, thus reducing runtime-overhead significantly.</span>
                    <br/>
                    <br/>
                    <span>The library also extends the</span>
                    <span className={"text-white"}>V1 & V2 Java SDKs</span>
                    <span>through the use of my</span>
                    <span className={"text-white"}>Domain Specific Language (DSLs)</span>
                    <span>and uniform</span>
                    <span className={"text-white"}>Extension Functions</span>
                    <span>for the Kotlin & Java SDKs.</span>
                </>
            )
        },
        {
            projectName: "pyTenable",
            badges: ["python", "maintainer", "contributor", "sdk", "tenable"],
            spanText: (
                <>
                    <span>I am a</span>
                    <span className={"text-white"}>maintainer</span>
                    <span>and an active</span>
                    <span className={"text-white"}>contributor</span>
                    <span>to this Open Source project, with over</span>
                    <span className={"text-white"}>85K monthly users,</span>
                    <span>managed by</span>
                    <span className={"text-white"}>Tenable.</span>
                    <span>This</span>
                    <span className={"text-white"}>Python SDK</span>
                    <span>will help Tenable's customers to interface with Tenable's</span>
                    <span className={"text-white"}>REST</span>
                    <span>and</span>
                    <span className={"text-white"}>GraphQL APIs.</span>
                    <br/>
                    <br/>
                    <span>My responsibilities include socializing with</span>
                    <span className={"text-white"}>Project Managers</span>
                    <span>and</span>
                    <span className={"text-white"}>Technical Directors</span>
                    <span>from across Tenable products to keep the library up-to-date as the public APIs evolve.</span>
                    <br/>
                    <br/>
                    <span>I also</span>
                    <span className={"text-white"}>review, approve pull requests</span>
                    <span>from contributors outside the organisation,</span>
                    <span className={"text-white"}>answer user queries</span>
                    <span>from GitHub Issues, and</span>
                    <span className={"text-white"}>release</span>
                    <span>the library for the general public.</span>

                    <span></span>
                    <span className={"text-white"}></span>
                </>
            )
        },
        {
            projectName: "Biblia-Sacra-Vulgata",
            badges: ["kotlin", "java", "coroutines", "author", "REST-API", "literature", "apache-license"],
            spanText: (
                <>
                    <span>I created this project to serve</span>
                    <span className={"text-white"}>The Latin Vulgate</span>
                    <span>and it's</span>
                    <span className={"text-white"}>English Translation</span>
                    <span>as a</span>
                    <span className={"text-white"}>REST API.</span>
                    <span>This API does not employ a backing database, in lieu, uses an</span>
                    <span className={"text-white"}>in-house built in-memory data store,</span>
                    <span>which allows for constant time look-ups</span>
                    <br/>
                    <br/>
                    <span>I intend to include support for</span>
                    <span className={"text-white"}>Graal VM Native Images</span>
                    <span>which allows</span>
                    <span className={"text-white"}>Ahead of Time (AOT) Compilation</span>
                    <span>capabilities, which will result in</span>
                    <span className={"text-white"}>significantly reduced start-up times</span>
                    <span>and</span>
                    <span className={"text-white"}>memory foot-print</span>


                    <br/>
                    <br/>

                    <span></span>
                    <span className={"text-white"}></span>
                </>
            )
        },
        {
            projectName: "catholic-cli",
            badges: ["python", "author", "maintainer", "contributor"],
            spanText: (
                <>
                    <span>I built this</span>
                    <span className={"text-white"}>Command Line Interface</span>
                    <span>application as a</span>
                    <span className={"text-white"}>Theological Knowledge base</span>
                    <span>about the Catholic Church and its teachings</span>
                    <span>for Theologian power users. This does search on various ancient</span>
                    <span className={"text-white"}>Latin</span>
                    <span>and</span>
                    <span className={"text-white"}>English</span>
                    <span>texts</span>
                    <br/>
                    <br/>
                    <span>This app is available for download at</span>
                    <span className={"text-white"}>pypi</span>
                </>
            )
        }
    ]

    return (
        <div className={
            `
            h-screen
            mx-auto
            bg-gray-950
            flex
            flex-col
            items-center 
            justify-center
            md:p-5
            `
        }>
            <GradientHeading text={"Open Source Contributions"}/>
            <Carousel projects={projects}/>
        </div>

    )
}
