export const Badge = (prop: BadgeProp) => {
    return (
        <div className={
            `
            flex 
            flex-row 
            rounded-xl
            p-[2px]
            m-[3px]
            w-fit
            bg-gradient-to-r 
            from-blue-400 
            via-green-300 
            to-indigo-200 
            `
        }>
            <img className={
                `
                    w-[15px]
                    h-[15px]
                    p-[2px]
                    `
            }
                 src={`/images/${prop.name}.png`}
                 alt={"GitHub Logo"}/>
            <p className={`text-gray-700 text-[10px] pl-1 pr-1 pt-[0.1px]`}>{prop.name}</p>
        </div>
    )
}

export interface BadgeProp {
    name: string
}
