export const TextArea = (props: TextAreaProps) => {
    return (
        <div className={
            `
            text-blue-50
            flex
            flex-col
            m-2
            mb-6
            `
        }>

            <label className={` p-2 text-gray-300`}>{props.label}</label>
            <textarea
                value={props.text}
                onChange={(e) => props.setText(e.target.value)}
                className={
                    `
                bg-gray-800
                h-24
                p-5
                rounded-xl
                hover:scale-105 
                transition duration-500
                text-blue-50
                w-[300px] md:w-[500px]
                `}
                placeholder={props.placeholder}
            />
            {props.errorMessage ? <small className={` p-1 text-red-400 `}>{props.errorMessage}</small> : null}
        </div>
    )
}

export interface TextAreaProps {
    label: string,
    placeholder: string,
    text: string,
    setText: (text: string) => void,
    errorMessage: string | null
}
