import {Name} from "../components/Name";
import {ShortDescription} from "../components/ShortDescription";

export const BasicIntroduction = () => {
    return (
        <div className={
            `
            md:flex-row flex-col
            bg-gray-950 
            min-h-screen flex items-center justify-center 
            `
        }>
            <div className={"md:w-1/2 w-screen"}>
                <Name/>
            </div>
            <div className={"md:w-1/2 w-screen"}>
                <ShortDescription/>
            </div>
        </div>
    )
}
