import {GradientHeading} from "../components/GradientHeading";
import {ContactForm} from "../components/ContactForm";

export const GetInTouch = () => {
    return (
        <div className={
            `
            h-screen
            mx-auto
            bg-gray-950
            flex
            flex-col
            items-center 
            justify-center
            md:p-5
            `
        }>
            <GradientHeading text={"Say Hello"}/>
            <ContactForm/>
        </div>
    )
}
