import {Dots} from "./Dots";
import Typewriter from "typewriter-effect";

/**
 * Functional Component for displaying Name with a greeting.
 * @constructor
 */
export const Name = () => {

    return (
        <div
            className={
                `
                p-5 md:p-20
                md:pb-10
                h-fit
                relative
                transition-all duration-1000 ease-in-out
                `
            }
        >
            <div className={"absolute z-0 pt-6"}>
                <Dots/>
            </div>
            <div className={"relative z-10 pl-10 pt-5"}>
                <h1
                    className={`
                    text-xl md:text-5xl 
                    font-sourceCodePro 
                    text-green-300
                    mb-3 lg:mb-5
                    `
                    }
                >
                    Hello there 👋🏼 I'm
                </h1>

                <div
                    className={
                        `
                        flex
                        flex-col sm:flex-row
                        space-y-3 sm:space-y-0
                        space-x-0 sm:space-x-8
                        `
                    }
                >
                    <div className={
                        `
                        text-7xl md:text-9xl
                        font-thin
                        font-rubik
                        text-gray-50
                        `
                    }>
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(`Aseem Savio`)
                                    .pauseFor(1000)
                                    .deleteAll()
                                    .typeString(" /əˈsiːm ˈseɪvi.oʊ/")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("Aseem Savio")
                                    .start();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
