import {Badge} from "./badges/Badge";
import {ReactElement} from "react";

export const ProjectTile = (props: ProjectTileProps) => {
    return (
        <div className={
            `
          h-[100%]
          w-[100%]
          inline-block
          p-10
          rounded-xl
          border-gray-900          
          `
        }>
            <div className={
                `
                flex
                flex-row
                `
            }>
                <img className={
                    `
                    p-1
                    w-7
                    h-7
                    rounded-full
                    bg-gradient-to-r 
                    from-blue-300 
                    via-green-200 
                    to-indigo-100
                    mr-2
                    `
                }
                     src={"/images/github.png"}
                     alt={"GitHub Logo"}/>

                <p className={
                    `
                font-sourceCodePro
                text-lg
                font-bold
                bg-gradient-to-r 
                from-blue-400 
                via-green-300 
                to-indigo-200 
                inline-block 
                text-transparent 
                bg-clip-text
                `
                }>
                    {props.projectName}
                </p>
            </div>
            <div className={
                `
                flex
                flex-wrap
                space-x-2
                pt-5
                
                `
            }>
                {props.badges.map((badge) => {
                    return <Badge name={badge}/>
                })}
            </div>
            <div className={
                `
                pt-5
                font-rubik
                text-sm
                text-gray-400
                space-x-1
                `
            }>
                {props.spanText}
            </div>
        </div>
    )
}

export interface ProjectTileProps {
    projectName: string,
    badges: string[],
    spanText: ReactElement
}
