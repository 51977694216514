import {ProjectTile, ProjectTileProps} from "./ProjectTile";
import {useState} from "react";

export const Carousel = (props: CarouselProps) => {

    const size = props.projects.length

    const [currentIndex, setCurrentIndex] = useState(0)

    const next = () => {
        if (currentIndex === size - 1) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const previous = () => {
        if (currentIndex === 0) {
            setCurrentIndex(size - 1)
        } else {
            setCurrentIndex(currentIndex - 1)
        }
    }

    return (
        <section className={"w-screen text-white"}>
            <div className={"flex"}>
                <div className={"w-1/12 md:w-2/12 flex items-center"}>
                    <div className={"w-full text-right"}>
                        <button
                            onClick={previous}
                            className={"p-2 md:p-5 mr-2 md:mr-5 rounded-full hover:bg-gray-800 duration-300"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    className={"flex w-8/12 md:p-10 h-96 overflow-x-hidden transition-transform ease-in-out duration-700"}>
                    <ProjectTile projectName={props.projects[currentIndex].projectName}
                                 badges={props.projects[currentIndex].badges}
                                 spanText={props.projects[currentIndex].spanText}
                    />
                </div>
                <div className={"w-1/12 md:w-2/12 flex items-center"}>
                    <div className={"w-full text-left"}>
                        <button
                            onClick={next}
                            className={"p-2 md:p-5 ml-2 md:ml-5 rounded-full hover:bg-gray-800 duration-300"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

interface CarouselProps {
    projects: ProjectTileProps[]
}
