export const Button = (props: ButtonProps) => {
    return (
        <div>
            <button
                onClick={props.onClick}
                className={
                    `
              bg-green-200
              px-20 md:px-36
              py-3
              rounded-xl
              `
                }
            >
                <p>{props.text}</p>
            </button>
        </div>
    )
}

export interface ButtonProps {
    text: string
    onClick: () => void
}
