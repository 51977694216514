export const GradientHeading = (props: GradientHeadingProps) => {
  return (
      <h1 className={
          `
                font-rubik
                text-5xl md:text-7xl
                bg-gradient-to-r 
                from-blue-600 
                via-green-500 
                to-indigo-400 
                inline-block 
                text-transparent 
                bg-clip-text
                pb-20
                p-5
                `
      }>{props.text}</h1>
  )
}

export interface GradientHeadingProps {
    text: string
}
