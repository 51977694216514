import {Input} from "./Input";
import {TextArea} from "./TextArea";
import {Button} from "./Button";
import {useState} from "react";

class Coordinates {
    latitude: number
    longitude: number

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude
        this.longitude = longitude
    }
}

export const ContactForm = () => {

    const [name, setName] = useState<string>("")
    const [nameError, setNameError] = useState<string | null>(null)

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<string | null>(null)

    const [message, setMessage] = useState<string>("")
    const [messageError, setMessageError] = useState<string | null>(null)

    const sendMessage = async () => {

        // initial clean up
        setNameError(null)
        setEmailError(null)
        setMessageError(null)
        let canSubmit = true

        if (name == null || name === "") {
            canSubmit = false
            setNameError("You should provide your name.")
        } else if (name.length > 50) {
            canSubmit = false
            setNameError("There's no way you have a name this long.")
        }

        if (email == null || email === "") {
            canSubmit = false
            setEmailError("You should provide your email.")
        } else if (email.length > 50) {
            canSubmit = false
            setEmailError("You should reconsider choosing your email length.")
        }

        if (message == null || message === "") {
            canSubmit = false
            setMessageError("You gotta type something here.")
        } else if (message.length > 2000) {
            canSubmit = false
            setEmailError("Can we keep this short?")
        }

        if (canSubmit) {

            const coordinates = await getGeoLocation()
            console.log(coordinates)

            const payload = {
                "name": name,
                "email": email,
                "message": message,
                "user_agent": navigator.userAgent,
                "latitude": "" + coordinates.latitude,
                "longitude": "" + coordinates.longitude
            }
            console.log(payload)
            await createMessage(payload)

        } else {
            alert("Could not send message.")
        }

    }


    return (
        <div className={
            `
          flex
          flex-col
          items-center 
          justify-center
          `
        }>
            <Input
                label={"Name"}
                type={"text"}
                placeholder={"Your Name"}
                errorMessage={nameError}
                text={name}
                setText={setName}
            />
            <Input
                label={"Email"}
                type={"email"}
                placeholder={"Your Email"}
                errorMessage={emailError}
                text={email}
                setText={setEmail}
            />
            <TextArea
                label={"Your Message"}
                placeholder={"What's your message for me?"}
                errorMessage={messageError}
                text={message}
                setText={setMessage}
            />
            <br/>
            <Button text={"Send"} onClick={sendMessage}/>
        </div>
    )
}

async function getGeoLocation(): Promise<Coordinates> {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    // Success callback
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve(new Coordinates(latitude, longitude));
                },
                function (error) {
                    // Error callback
                    reject(new Coordinates(0, 0));
                }
            );
        } else {
            reject(new Error("Geolocation is not available."));
        }
    });
}

async function createMessage(payload: any) {
    const url = "https://api.aseemsavio.com/aseemsavio/messages"
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    if (response.ok) {
        alert("Your message reached me. I'll try to get back to you soon!")
    } else {
        alert("Could not send this message. Could you try this from a different browser?")
    }
}
