import React, {useEffect} from 'react';
import {HomePage} from "./pages/HomePage";

function App() {

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.aseemsavio.com/aseemsavio/visits", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "timestamp": new Date().getTime(),
                        "user_agent": navigator.userAgent,
                        "source": "0000"
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data); // Handle response data here
            } catch (error) {
                console.error('Error:', error); // Handle errors here
            }
        };

        fetchData().then(r => console.log("Reported visit."));
    }, []);

    return (
        <HomePage/>
    );
}

export default App
