import {HTMLInputTypeAttribute} from "react";

export const Input = (props: TextInputProps) => {
    return (
        <div className={
            `
          text-blue-50
          flex
          flex-col
          m-2
          `
        }>
            <label className={` p-2 text-gray-300 `}>{props.label}</label>
            <input
                type={props.type}
                value={props.text}
                onChange={(e) => props.setText(e.target.value)}
                placeholder={props.placeholder}
                className={
                    `
              bg-gray-800
              h-12
              p-5
              rounded-xl
              hover:scale-105 
              transition duration-500
              text-blue-50
              w-[300px] md:w-[500px]
              `
                }/>
            {props.errorMessage ? <small className={` p-1 text-red-400 `}>{props.errorMessage}</small> : null}
        </div>
    )
}

export interface TextInputProps {
    label: string,
    type: HTMLInputTypeAttribute,
    text: string,
    setText: (text: string) => void,
    placeholder: string,
    errorMessage: string | null
}
