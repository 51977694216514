import {useUserData} from "../hooks/UseData";
import {LinkButton} from "./LinkButton";

export const ShortDescription = () => {

    const user = useUserData()

    const yearsOfExperience = (year: string): number => {
        const today = new Date();
        const startDate = new Date(year);

        const diffInMilliseconds = today.getTime() - startDate.getTime();
        const years = diffInMilliseconds / (365 * 24 * 60 * 60 * 1000);

        return Math.floor(years);
    };


    return (
        <div className={
            `
            p-10 md:pt-0 md:pl-20 md:pr-20
            pt-5
            font-rubik
            text-lg
            text-gray-500
            space-x-2
            `
        }>
            <span>⚡️</span>
            <span>A</span>
            <span className={"text-white"}>{user?.professionalInformation.role}</span>
            <span>with over</span>
            <span className={"text-white"}>{yearsOfExperience(user?.professionalInformation.joiningDate!!)}+ years</span>
            <span>of expertise specializing in</span>
            <span>{user?.professionalInformation.responsibilities.verb}</span>
            <span className={"text-white"}>{user?.professionalInformation.responsibilities.subject}</span>
            <div className={
                `
                flex
                flex-row
                content-center
                justify-center
                pt-5
                hover:scale-105
                `
            }>
                <LinkButton image={"/images/drawing.png"} name={"Blog"} link={"https://blog.aseemsavio.com/"}/>
            </div>
        </div>
    )
}
