import {useEffect, useState} from "react";
import {UserInfo} from "./Data";
import {fetchData} from "./DataFetcher";
import {DATA_FILE_NAME} from "../constants";

/**
 * Hook that fetches the user data from 'data.json' inside the public folder.
 * @param filePath
 */
export const useUserData = (filePath: string = DATA_FILE_NAME) => {
    const [userInfo, setUserInfo] = useState<UserInfo>()

    useEffect(() => {
        const fetchDataAndSetUserInfo = async () => {
            try {
                const data: UserInfo = await fetchData(filePath);
                setUserInfo(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchDataAndSetUserInfo().then(r => console.log(`Promise has returned: ${r}`));
    }, [filePath]);

    return userInfo
}
