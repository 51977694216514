export const LinkButton = (prop: LinkButtonProps) => {
  return (
      <a href={prop.link}>
          <div className={
              `
            flex
            flex-row
            h-[40px]
            w-[100px]
            rounded-xl
            text-white
            content-center
            justify-center
            items-center
            border-green-200
            border-[1px]
            
          `
          }>

              <img
                  className={
                      `
                w-[20px]
                h-[20px]
                pb-[5px]
                pr-[5px]
              `
                  }
                  src={prop.image} alt={prop.name}
              />
              <p>{prop.name}</p>
          </div>
      </a>
  )
}


export interface LinkButtonProps {
    image: string,
    name: string,
    link: string
}
